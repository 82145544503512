import React from "react";
import "./styles/styles.css";

// Component
import Main from "./components/Main/Main";
import Header from "./components/Header/Header";
import HelmetData from "./components/HelmetData/HelmetData";

import maestus from "./components/data/Band";

function App() {
  const { navItems, allReleases } = maestus;
  const featuredRelease = allReleases[0]; // Eventually this might be a script that grabs the release with the latest date in it, but for now it's just grabing the first one

  return (
    <div className="App">
      <HelmetData />
      <Header />
      <Main navItems={navItems} featuredRelease={featuredRelease} />
    </div>
  );
}

export default App;
