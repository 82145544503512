import React from "react";
import logo from "../../styles/images/Maestus_Logo_NoBackground.png";

type Props = {
  name: string;
};

const Header = ({ name }: Props) => {
  return (
    <header>
      <img src={logo} alt="Maestus Logo" />
    </header>
  );
};

export default Header;
