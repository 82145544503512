import React from "react";
import { Release } from "../data/Band";

type Props = {
  featuredRelease: Release;
};

const Content = ({ featuredRelease }: Props) => {
  const { name, featuredVideoEmbed } = featuredRelease;
  return (
    <div className="content--main">
      <iframe
        width="560"
        height="315"
        title={`Maestus - ${name} embed`}
        src={`${featuredVideoEmbed}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default Content;
