import React from "react";
import { Release } from "../data/Band";

type Props = {
  featuredRelease: Release;
};

const BcPlayer = ({ featuredRelease }: Props) => {
  const albumData = featuredRelease.bandcampEmbed;

  type Album = {
    name: string;
    machine_name: string;
    id: string;
    tracks: string[];
  };

  function getRandomInt(max: number) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  const getRandomTrack = (albumData: Album) => {
    let albumId: string = albumData.id;
    let tracks: string[] = albumData["tracks"];
    let randomInt: number = getRandomInt(tracks.length);
    let trackSrc: string = `https://bandcamp.com/EmbeddedPlayer/album=${albumId}/size=small/bgcol=333333/linkcol=0f91ff/track=${tracks[randomInt]}/transparent=true/`;
    return trackSrc;
  };

  const albumMachineName = `https://glossolaliarecords.bandcamp.com/album/${albumData.machine_name}`;

  return (
    <div className="bc--player">
      <iframe
        title="Maestus Bandcamp Player"
        style={{ border: "0", width: "100%", height: "42px" }}
        src={getRandomTrack(albumData)}
        seamless
      >
        <a href={albumMachineName}>{albumData.name} by Maestus</a>
      </iframe>
    </div>
  );
};

export default BcPlayer;
