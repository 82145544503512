import maestus from "./Band";

const { name, featuredRelease, social_links, website } = maestus;
const { name: albumName, year } = featuredRelease;

const title = `${name} | Official Site`.toUpperCase();
const description =
  `${name} - ${albumName} (${year}) - available now on Glossolalia Records`.toUpperCase();

const siteData = {
  title,
  description,
  char_set: "utf-8",
  type: "website",
  name: `${name} | Official Site`,
  photo: "",
  link_href: `${website}`,
  local: "en_US",
  og_img: "",
  og_img_width: "",
  og_img_height: "",
  og_img_alt: `official-${name}-logo`,
  site_name: `${name}`,
  twitter: {
    card: "summary",
    site: `@${social_links.twitter.url}`,
  },
};

// Data/Social Media Updates
siteData.og_description = siteData.description;
siteData.og_img_secure = siteData.og_img;

// Twitter data updates
siteData.twitter.description = siteData.description;
siteData.twitter.title = siteData.title;
siteData.twitter.img = siteData.og_img;
siteData.twitter.creator = siteData.twitter.site;

export default siteData;
