import React from "react";

// Components
import Content from "../Content/Content";
import Navigation from "../Navigation/Navigation";
import MobileNav from "../MobileNav/MobileNav";
import BcPlayer from "../BcPlayer/BcPlayer";
import { Release } from "../data/Band";

// import maestus from "../data/Band";

type Props = {
  navItems: any[];
  featuredRelease: Release;
};

const Main = ({ navItems, featuredRelease }: Props) => {
  return (
    <div className="main-background">
      <main>
        <MobileNav navItems={navItems} />
        <Content featuredRelease={featuredRelease} />
        <Navigation />
        <BcPlayer featuredRelease={featuredRelease} />
      </main>
    </div>
  );
};

export default Main;
