import {
  faBandcamp,
  faFacebook,
  faInstagram,
  faSpotify,
  faYoutube,
  IconDefinition,
} from "@fortawesome/free-brands-svg-icons";
import {
  faCompactDisc,
  faEnvelope,
  faList,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../../styles/images/Maestus_Logo_NoBackground.png";
import background from "../../styles/images/maestus_background.jpg";

type Social = {
  title: string;
  url: string;
};

type Member = {
  name: string;
  instrument: string;
  instagram: string;
};

type BandcampEmbed = {
  name: string;
  machine_name: string;
  id: string;
  tracks: string[];
};

export interface Release {
  name: string;
  bandcampUrl: string;
  storeUrl?: string;
  featuredVideoEmbed: string;
  backgroundImg: string;
  bandcampEmbed: BandcampEmbed;
  year: string;
}
type NavItem = {
  name: string;
  link: string;
  icon?: IconDefinition;
  active: boolean;
  desktop: boolean;
  mobile: boolean;
  key: number;
};

export interface Band {
  name: string;
  bandcampUrl: string;
  logo: string;
  social_links: {
    instagram: Social;
    facebook: Social;
    twitter: Social;
  };
  photos?: string;
  featuredRelease: Release;
  allReleases?: Release[];
  members: Member[];
  navItems: NavItem[];
  website: string;
}
const maestus: Band = {
  name: "Maestus",
  website: "https://maest.us",
  bandcampUrl: "https://maestus.bandcamp.com",
  logo: logo,
  social_links: {
    instagram: {
      title: "Instagram",
      url: "maestusofficial",
    },
    facebook: {
      title: "Facebook",
      url: "maestusdoom",
    },
    twitter: {
      title: "Twitter",
      url: "maestusdoom",
    },
  },
  photos: "",
  members: [
    {
      name: "SP",
      instrument: "Guitar/Vocals",
      instagram: "stephenparkhum",
    },
    {
      name: "SB",
      instrument: "Keyboards/Vocals",
      instagram: "Sssp0re",
    },
    {
      name: "KP",
      instrument: "Bass/Vocals",
      instagram: "glossolaliarecords",
    },
    {
      name: "NK",
      instrument: "Guitar",
      instagram: "_khooood",
    },
    {
      name: "CC",
      instrument: "Drums",
      instagram: "cordell.cline",
    },
  ],
  allReleases: [
    // Always put the latest album first
    {
      name: "Daybreak's Advent",
      year: "2020",
      bandcampUrl: "https://maestus.bandcamp.com/album/daybreaks-advent",
      bandcampEmbed: {
        name: "Daybreak's Advent",
        machine_name: "daybreaks-advent",
        id: "2278345818",
        tracks: ["1236276314", "616080703"],
      },
      backgroundImg: background,
      featuredVideoEmbed: "https://www.youtube-nocookie.com/embed/jhCKqEnmOKc",
    },
  ],
  featuredRelease: {
    name: "Daybreak's Advent EP",
    bandcampUrl: "https://maestus.bandcamp.com/album/daybreaks-advent",
    featuredVideoEmbed: "https://www.youtube-nocookie.com/embed/qIOUIid_-ks",
    backgroundImg: background,
    bandcampEmbed: {
      name: "Daybreak's Advent",
      machine_name: "daybreaks-advent",
      id: "2278345818",
      tracks: ["1236276314", "616080703"],
    },
    year: "2020",
  },
  navItems: [
    {
      name: `Daybreak's Advent`,
      link: "https://maestus.bandcamp.com/album/daybreaks-advent",
      icon: faBandcamp,
      active: true,
      desktop: true,
      mobile: true,
      key: 9,
    },
    {
      name: `Daybreak's Advent (CS)`,
      link: "https://glossolaliarecords.bandcamp.com/album/daybreaks-advent",
      icon: faBandcamp,
      active: true,
      desktop: true,
      mobile: true,
      key: 1,
    },
    {
      name: "Spotify",
      link: "https://open.spotify.com/album/3CJkPdj4uvVSqq296Ul2bD?si=WrxaqLovQMmrGhPlTH6QQg",
      icon: faSpotify,
      active: true,
      desktop: true,
      mobile: true,
      key: 5,
    },
    {
      name: "EPK",
      link: "https://www.sonicbids.com/band/maestus/",
      icon: faCompactDisc,
      active: true,
      desktop: false,
      mobile: true,
      key: 5,
    },
    {
      name: "Instagram",
      link: "https://instagram.com/maest.us",
      icon: faInstagram,
      active: true,
      desktop: true,
      mobile: true,
      key: 2,
    },
    {
      name: "YouTube",
      link: "https://www.youtube.com/channel/UCX73t3G2y82xKbZ2Jcmr0qg",
      icon: faYoutube,
      active: true,
      desktop: true,
      mobile: true,
      key: 6,
    },
    {
      name: "Facebook",
      link: "https://facebook.com/maestusdoom",
      icon: faFacebook,
      active: true,
      desktop: false,
      mobile: true,
      key: 8,
    },
    {
      name: "Live",
      link: "/live",
      icon: faList,
      active: false,
      desktop: true,
      mobile: true,
      key: 3,
    },
    {
      name: "Contact",
      link: "mailto:maestus.pnw@gmail.com",
      icon: faEnvelope,
      active: true,
      mobile: true,
      desktop: true,
      key: 4,
    },
  ],
};

export default maestus;
